import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import TradepersonDashboard from "../src/components/TradepersonDashboard";
import Navbar from "./components/Navbar";
import { AuthContextProvider, AuthContext } from "./context/AuthContext";
import LoginForm from "./components/LoginForm";
import SignupForm from "./components/SignupForm";
import Services from "./pages/Services";
import AboutUs from "./pages/Aboutus";
import Footer from "./pages/Footer";
import OurTeam from "./pages/OurTeam";
import FAQs from "./pages/FAQs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import JobSearch from "./components/JobSearch";
import FreelancerDashboard from "./pages/FreelancerDashboard";
import ClientDashboard from "./components/ClientDashboard";
import DonationPage from "./pages/DonationPage";
import "./app.css";
import AdminDashboard from "./admin/AdminDashboard";
import AdminLogin from "./admin/AdminLogin";
import SubadminDashboard from "./admin/SubadminDashboard";
import JobApplication from "./components/JobApplication";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <Router>
      <AuthContextProvider>
        <Navbar />
        <div className="page-content">
          <Routes>

            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/Subadmin-Dashboard" element={<SubadminDashboard />} />
            <Route path="/admin-login" element={<AdminLogin />} />


            {/* Public Routes */}
            <Route path="/" element={<HomePage />} />
            {/* Protected Routes */}
            <Route path="/client-dashboard"
              element={
                <ProtectedRoute>
                  <ClientDashboard />
                </ProtectedRoute>
              } />

            <Route path="/freelancer-dashboard"
              element={
                <ProtectedRoute>
                  <FreelancerDashboard />
                </ProtectedRoute>
              } />


            <Route path="/tradeperson-dashboard"
              element={
                <ProtectedRoute>
                  <FreelancerDashboard />
                </ProtectedRoute>
              } />

            <Route path="/job-search"
              element={
                <ProtectedRoute>
                  <JobSearch />
                </ProtectedRoute>
              } />

            {/* Protected Routes */}

            <Route path="/apply/:jobId" element={<JobApplication />} />
            <Route path="/services" element={<Services />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/donation-page" element={<DonationPage />} />



            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />

            {/* Protected Routes */}



          </Routes>
        </div>

        <Footer />
      </AuthContextProvider>
    </Router>
  );
}

export default App;
